import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { QuotationService } from '../quotation.service';
import { ToasterService } from 'src/app/service/toaster.service';

@Component({
  selector: 'app-employee-modal',
  templateUrl: './employee-modal.component.html',
  styleUrls: ['./employee-modal.component.css']
})
export class EmployeeModalComponent {
//   @Input() id?: string;
//   isOpen = false;
//   private element: any;
//   constructor(private service: QuotationService, private el: ElementRef) {
//     this.element = el.nativeElement;
//    } // Inject modal content component (optional)

//   ngOnInit() {
//     // add self (this modal instance) to the modal service so it can be opened from any component
//     this.service.add(this);

//     // move element to bottom of page (just before </body>) so it can be displayed above everything else
//     document.body.appendChild(this.element);

//     // close modal on background click
//     this.element.addEventListener('click', (el: any) => {
//         if (el.target.className === 'jw-modal') {
//             this.close();
//         }
//     });
// }

// ngOnDestroy() {
//     // remove self from modal service
//     this.service.remove(this);

//     // remove modal element from html
//     this.element.remove();
// }

// open() {
//     console.log(true);
//     this.element.style.display = 'block';
//     document.body.classList.add('jw-modal-open');
//     this.isOpen = true;
// }

// close() {
//     this.element.style.display = 'none';
//     document.body.classList.remove('jw-modal-open');
//     this.isOpen = false;
// }
@Input() id: any;
@Output() close = new EventEmitter();
employees: any;
selectedEmployeeId: any;
constructor(public quotationService: QuotationService, public toastr: ToasterService) {
    this.quotationService.getContractorEmployees().subscribe((resp: any) => {
        console.log(resp);
        this.employees = resp.data.data;
    })
}

selectEmployee(employee: any) {
    console.log(employee);
    this.selectedEmployeeId = employee.id;
}
assignEmployee() {
    let data = {
        'quotation_id': this.id,
        'assigned_id': this.selectedEmployeeId
    }
    this.quotationService.assignQuotation(data).subscribe((resp: any) => {
        console.log(resp);
        if(resp && resp?.success === true){
            this.closeModal();
            this.toastr.success('Quotation assigned successfully.', 'Success');
        }
    })
}

closeModal(){
    this.close.emit();  
}
}
