import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { QuotationService } from '../quotation.service';
import { constants } from '../../utils/constant';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Destroy } from 'src/app/service/destroy';
import { StorageService } from 'src/app/service/storage.service';


type currentUrl = 'job-history' | 'quotations' | 'cancelled-jobs';

@Component({
  selector: 'app-quotation-list',
  templateUrl: './quotation-list.component.html',
  styleUrls: ['./quotation-list.component.css'],
  viewProviders: [Destroy]
})
export class QuotationListComponent {
  selectedQuotationType = 'new_jobs'
  quotationsTypes: string[] = constants.quotationType; 
  quotations: any;
  quotationsTableHeader: any;
  currentUrl: currentUrl = 'quotations';
  login_type : string = "";

  constructor(public quotationService: QuotationService, public router: Router,  private activatedRoute: ActivatedRoute, private destroy$: Destroy, private storage: StorageService) {
    this.storage.getItem('local', 'authData').pipe(takeUntil(destroy$)).subscribe((localData: any) => {
      this.login_type = localData.sub_type;
    })
    // this.quotationService.changeQuotationType(this.selectedQuotationType);

    // this.quotations$ = this.quotationService.quotationList$.pipe(
    //   tap(x => console.log(x))
    // );
  }

  ngOnInit(): void {
    console.log(this.activatedRoute);
    this.activatedRoute.url.pipe(takeUntil(this.destroy$)).subscribe((u: any) => {
      console.log(u);
      this.currentUrl = u && u.length > 0 ? u[0].path : 'quotations'; 
      if(this.currentUrl === 'job-history'){
        this.generateTableHeader();
        this.quotationsTypes = [];
        this.selectedQuotationType = 'completed_jobs';
        this.getQuotations();
      } else if(this.currentUrl === 'cancelled-jobs'){
        this.generateTableHeader('cancelled_jobs');
        this.quotationsTypes = [];
        this.selectedQuotationType = 'cancelled_jobs';
        this.getQuotations();
      }
      else {
        this.generateTableHeader();
        this.renderComponentData(this.login_type);
      }
    });
  }

  renderComponentData(userType: string) {
    if(userType === 'COMPANY'){
      this.quotationsTypes = this.quotationsTypes.filter(ele => ele !== 'assigned_jobs' && ele !== 'cancelled_jobs');
    }
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params);
      if(params['selected_type'] && this.quotationsTypes.includes(params['selected_type']) ) {
      this.selectedQuotationType = params['selected_type'];
      }else {
        this.selectedQuotationType = 'new_jobs'
      }
    });
    // this.quotations$.subscribe((resp) => console.log(resp));
    this.getQuotations();
    
  }

  getQuotations(jumpToPage: string = "1") {
    this.quotationService.getQuotationList(this.selectedQuotationType, jumpToPage).pipe(takeUntil(this.destroy$)).subscribe(resp => {
      if(resp) {
        this.quotations = resp.data;
      }
    })
  }

  changeQuotationType(type: string) {
    this.selectedQuotationType = type;
    this.router.navigate(['/quotations'], {queryParams: {selected_type: this.selectedQuotationType}, queryParamsHandling:'merge'});
    this.getQuotations();
  }

  viewSelectedRow(event: any) {
    console.log(event);
    this.router.navigate([`/${this.currentUrl}/details`], {queryParams: {selected_type: this.selectedQuotationType, request_id: event.id}})
  }
  
  generateTableHeader(columnFor?:string){
    let header =[{
      title: 'Job Title & Description',
      width: '40%'
    }, {
      title: 'Location | Date & Time',
      width: '30%'
    }, {
      title: 'Job Status',
      width: '20%'
    },{
      title: '',
      width: '10%'
    }];
    let cancelledJobsHeaders = [{
      title: 'Job Title & Description',
      width: '40%'
    }, {
      title: 'Location | Date & Time',
      width: '20%'
    }, {
      title: 'Status',
      width: '10%'
    },{
      title: 'Total Costs',
      width: '15%'
    },{
      title: 'Penalty Cost',
      width: '15%'
    }];

    this.quotationsTableHeader = columnFor === 'cancelled_jobs' ? cancelledJobsHeaders : header;
  }

  changePage(event: any) {
    console.log(event);
    this.getQuotations(event);
  }
}
