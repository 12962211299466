import { Injectable } from '@angular/core';
import { Observable, Subject, switchMap } from 'rxjs';
import { HttpService } from '../service/http.service';
import { environment } from 'src/environments/environment';
import { EmployeeModalComponent } from './employee-modal/employee-modal.component';



@Injectable({
  providedIn: 'root'
})
export class QuotationService {

  apiBaseUrl = environment.apiBaseUrl;
  quotationURL = '/contractor/repair-requests';
  private modals: EmployeeModalComponent[] = [];
  private selectedQuotationType = new Subject<string>();
  selectedQuotationTypeAction$ = this.selectedQuotationType.asObservable();
  constructor(public http: HttpService) { }

    quotationList$ =  this.selectedQuotationTypeAction$.pipe(switchMap((quotation: string) => this.http.get(`${this.apiBaseUrl}${this.quotationURL}?type=${quotation}`)))
  
    changeQuotationType(type: string): void {
      this.selectedQuotationType.next(type);
    }


    getQuotationList(type: string, page?: string) : Observable<any>{
      return this.http.get(`${this.apiBaseUrl}${this.quotationURL}?type=${type}&page=${page}`);
    }

    getQuotationDetails(id: string): Observable<any> {
      return this.http.get(`${this.apiBaseUrl}${this.quotationURL}-detail/${id}`);
    }

    postQuotations(data: any): Observable<any> {
      return this.http.post(`${this.apiBaseUrl}/contractor/post-quotation`, data);
    }

    getContractorEmployees(): Observable<any> {
      return this.http.get(`${this.apiBaseUrl}/contractor/get-employee-list`);
    }

    assignQuotation(data: any): Observable<any> {
      return this.http.post(`${this.apiBaseUrl}/contractor/assign-quotation`, data);
    }

  //   add(modal: EmployeeModalComponent) {
  //     // ensure component has a unique id attribute
  //     if (!modal.id || this.modals.find(x => x.id === modal.id)) {
  //         throw new Error('modal must have a unique id attribute');
  //     }

  //     // add modal to array of active modals
  //     this.modals.push(modal);
  // }

  // remove(modal: EmployeeModalComponent) {
  //     // remove modal from array of active modals
  //     this.modals = this.modals.filter(x => x === modal);
  // }

  // open(id: string) {
  //     // open modal specified by id
  //     const modal = this.modals.find(x => x.id === id);

  //     if (!modal) {
  //         throw new Error(`modal '${id}' not found`);
  //     }

  //     modal.open();
  // }

  // close() {
  //     // close the modal that is currently open
  //     const modal = this.modals.find(x => x.isOpen);
  //     modal?.close();
  // }
  }
