<nav class="custom-nav-bar">
    <!-- page sidebar start -->
    <div class="page-sidebar">
        <div class="background-wrap">
            <div class="sidenav-background-image">
                <img src="../../../assets/icons/sidebar/background.png"
                    class="img-fluid for-light" alt>
            </div>
            <div class="sidenav-profile-image">
                <img src="../assets/images/logo/9.png"
                    class="img-fluid for-dark" alt>
            </div>
            <div class="sidebar-name">
                <h6 class="mb-0">{{userData?.name | titlecase}} </h6>
                <label>{{userData?.sub_type}}</label>
            </div>

        </div>
        <div class="main-sidebar">

            <ul class="custom-scrollbar">
                <ng-container  *ngFor="let item of navItems"> 
                
                <li class="sidebar-item"  (click)="routeTo(item.routerLink)">
                    <a class="sidebar-link" [ngClass]="currentUrl.includes(item.routerLink) ? 'active' : ''" >
                        <i [class]="item.iconClass"></i>
                        <span>{{item?.name}}</span>
                    </a>
                </li>
            </ng-container>
            </ul>

        </div>
    </div>
    <!-- page sidebar end -->
</nav>