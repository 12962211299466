import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MakeOfferComponent } from 'src/app/quotation/make-offer/make-offer.component';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.css'],
  standalone: true,
  imports: [CommonModule]
})
export class RightSidebarComponent implements OnInit, OnDestroy {
  isOpen = false;
  activeComponent: any;

  constructor() {}

  openOverlay(action: string, data?: any) { // Optional data parameter for communication
    this.isOpen = true;

    // Determine which action component to render based on 'action' parameter:
    switch (action) {
      case 'makeOffer':
        this.activeComponent = MakeOfferComponent;
        break;
      // Add more cases for other actions
      default:
        this.activeComponent = null;
    }
  }
  ngOnInit(): void {
    console.log('Modal init');
  }

  closeMe() {
  }

 ngOnDestroy(): void {
    console.log(' Modal destroyed');
  }
}
