<section class="component-container">
    <h3 class="page-title">{{ currentUrl | quotationType}}</h3>

    <div class="custom-tabs" *ngIf="currentUrl === 'quotations'">
        <ng-container *ngFor="let type of quotationsTypes">
            <button class="tab-button" (click)="changeQuotationType(type)"
                [ngClass]="type == selectedQuotationType ? 'active' : ''">{{type | quotationType }}</button>
        </ng-container>
    </div>
    
    <ng-container *ngIf="quotations">
        <app-common-table [data]="quotations" [columnData]="quotationsTableHeader"
        (viewRow)="viewSelectedRow($event)" (changePage)="changePage($event)" [tableFor]="currentUrl" ></app-common-table>
    </ng-container>
</section>