import { Roles } from "./role"

export interface navs {
    id: number,
    name: string,
    icon: string,
    routerLink: string,
    iconClass: string,
    hasSubMenu: boolean,
    parentId: number,
    roles: string[]
}

export const navigationItems: navs[] = [
    {
        id: 1,
        name: 'Dahsboard',
        icon: '',
        routerLink: '/dashboard',
        iconClass: 'sidebar-dashboard',
        hasSubMenu: false,
        parentId: 0,
        roles: ['COMPANY', 'EMPLOYEE', 'FREELANCE']
    },
    {
        id: 2,
        name: 'Quotations',
        icon: '',
        routerLink: '/quotations',
        iconClass: 'sidebar-quotations',
        hasSubMenu: false,
        parentId: 0,
        roles: ['COMPANY', 'EMPLOYEE', 'FREELANCE']
    },
    {
        id: 3,
        name: 'Chats',
        icon: '',
        routerLink: '/chats',
        iconClass: 'sidebar-chat',
        hasSubMenu: false,
        parentId: 0,
        roles: ['COMPANY', 'EMPLOYEE', 'FREELANCE']
    },
    {
        id: 4,
        name: 'My Earnings',
        icon: '',
        routerLink: '/earnings',
        iconClass: 'sidebar-earnings',
        hasSubMenu: false,
        parentId: 0,
        roles: ['COMPANY', 'EMPLOYEE', 'FREELANCE']
    },
    {
        id: 5,
        name: 'My Reviews',
        icon: '',
        routerLink: '/reviews',
        iconClass: 'sidebar-reviews',
        hasSubMenu: false,
        parentId: 0,
        roles: ['COMPANY', 'EMPLOYEE', 'FREELANCE']
    },
    {
        id: 6,
        name: 'Job History',
        icon: '',
        routerLink: '/job-history',
        iconClass: 'sidebar-history',
        hasSubMenu: false,
        parentId: 0,
        roles: ['COMPANY', 'EMPLOYEE', 'FREELANCE']
    },
    {
        id: 7,
        name: 'Cancelled Jobs',
        icon: '',
        routerLink: '/cancelled-jobs',
        iconClass: 'sidebar-cancelled-jobs',
        hasSubMenu: false,
        parentId: 0,
        roles: ['COMPANY', 'EMPLOYEE', 'FREELANCE']
    },
    {
        id: 8,
        name: 'My Documents',
        icon: '',
        routerLink: '/documents',
        iconClass: 'sidebar-documents',
        hasSubMenu: false,
        parentId: 0,
        roles: ['COMPANY', 'EMPLOYEE', 'FREELANCE']
    },
    {
        id: 9,
        name: 'Employee List',
        icon: '',
        routerLink: '/employees',
        iconClass: 'sidebar-employees',
        hasSubMenu: false,
        parentId: 0,
        roles: ['COMPANY']
    },
    {
        id: 10,
        name: 'My Profile',
        icon: '',
        routerLink: '/profile',
        iconClass: 'sidebar-profile',
        hasSubMenu: false,
        parentId: 0,
        roles: ['COMPANY', 'EMPLOYEE', 'FREELANCE']
    }
]