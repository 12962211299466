import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { takeUntil } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
import { Destroy } from 'src/app/service/destroy';

@Component({
  selector: 'app-cancellation-reschedule-policy',
  templateUrl: './cancellation-reschedule-policy.component.html',
  styleUrls: ['./cancellation-reschedule-policy.component.css'],
  standalone: true,
  imports: [CommonModule],
  providers: [Destroy]
})
export class CancellationReschedulePolicyComponent {

  policies: any;

  constructor(private commonService: CommonService, private destroy$: Destroy) {
    this.getCancellationPolicy();
  }


  getCancellationPolicy() {
    this.commonService.getPolicies("USER", "CANCELLATION_POLICY").pipe(takeUntil(this.destroy$)).subscribe((data:any) => {
      this.policies = data.data;
    })
  }
}
