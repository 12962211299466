import { Component, SimpleChanges } from '@angular/core';
import { StorageService } from './service/storage.service';
import { Subscription, delay } from 'rxjs';
import { LoaderService } from './service/loader.service';
import { AuthService } from './service/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'fixz';
  isLoggedIn: boolean = false;
  storage$: Subscription = new Subscription();
  isLoading = false;
  constructor(public storage: StorageService, public loading: LoaderService, private auth: AuthService){
    this.storage$ = this.storage.getItem('local', 'authData').subscribe((user: any) => {
      if(user) {
        this.isLoggedIn = true;
      }else {
      this.isLoggedIn = false;
      }
    });
  }

  ngOnInit(): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.listenToLoading();
    this.auth.generateSideNav();
    
  }

  listenToLoading(): void {
    this.storage$ = this.loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.isLoading = loading;
      });
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.storage$.unsubscribe();
  }
}
