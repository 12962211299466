import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParamsOptions } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(private http: HttpClient) { }

private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
        console.error('An error has occurred:', error.error);
    } else {
        console.error(
            `Backend has returned code ${error.status}, 
            the body was: `, error.error);
    }
    return throwError( () => 
        'An error occurred; please re-try the request.'
    );
}

  get(url: string, httpOptions?: any): Observable<any> {
    return this.http.get(url, httpOptions).pipe(
      tap(data => console.log(data))
    );
  }

  post(url: string, body: any, options?: any): Observable<any> {
    return this.http.post(url, body, options).pipe(
      tap(data => console.log(data))
    );
  }

}
