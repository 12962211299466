<div class="overlay">
    <div class="makeOffer shadow">
<form class="container" #form="ngForm" (ngSubmit)="submit(form)">
    <div class="row mt-3">
        <div class="col-10">
            <b>Make Offer</b>
        </div>
        <div class="col-2">
            <button type="button" class="close" aria-label="Close" (click)="closeOverlay()">
                <span aria-hidden="true">&times;</span>
              </button>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-12 mb-2">
            <div class="form-group">
                <label class="mb-0 font-weight-normal">Cost <span class="text-danger">*</span></label>
                <input type="text" class="form-control" required name="cost" #costInput="ngModel" [(ngModel)]="cost" />
              </div>
        </div>
        <div class="col-12 mb-2">
            <div class="form-group">
                <label class="mb-0 font-weight-normal">Job Complete time <span class="text-danger">*</span></label>
                <div class="position-relative input-group">
                    <input type="number" min="0" class="form-control" required name="total_timeline" #timeLineInput="ngModel"
                        [(ngModel)]="totalTimeline">
                    <div class="btn-group btn-group-toggle makeoffer-btn-group">
                        <label class="btn btn-sm btn-outline-primary" [ngClass]="{'active': timeType === 'Days'}">
                            <input type="radio" name="timeline_type" autocomplete="off" [value]="timeType" [(ngModel)]="timeType"
                                (click)="chooseTimeType('Days')" /> Days
                        </label>
                        <label class="btn  btn-sm btn-outline-primary" [ngClass]="{'active': timeType === 'Hours'}">
                            <input type="radio" name="timeline_type" autocomplete="off" [value]="timeType" [(ngModel)]="timeType"
                                (click)="chooseTimeType('Hours')" /> Hours
                        </label>
                        
                      </div>
                </div>
                
              </div>
        </div>
        <div class="col-12 mb-2">
            <div class="form-group">
                <label class="mb-0 font-weight-normal">Message <span class="text-danger">*</span></label>
                <textarea type="text" class="form-control" rows="5" required name="description" #descriptionInput="ngModel"
                    [(ngModel)]="description"></textarea>
              </div>
        </div>
        
    </div>
    <div class="d-flex makeOfferSubmit">
        <button type="submit" class="mt-auto btn btn-lg btn-block btn-theme">Submit</button>
    </div>
</form>
</div>
</div>