import { CancellationReschedulePolicyComponent } from './../components/cancellation-reschedule-policy/cancellation-reschedule-policy.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuotationListComponent } from './quotation-list/quotation-list.component';
import { QuotationDetailComponent } from './quotation-detail/quotation-detail.component';
import { MakeOfferComponent } from './make-offer/make-offer.component';
import { CommonTableComponent } from '../components/common-table/common-table.component';
import { RightSidebarComponent } from '../components/right-sidebar/right-sidebar.component';
import { FormsModule } from '@angular/forms';
import { EmployeeModalComponent } from './employee-modal/employee-modal.component';
import { PipeModule } from '../pipes/pipe.module';
import { FaqsComponent } from '../components/faqs/faqs.component';

@NgModule({
  declarations: [
    QuotationListComponent,
    QuotationDetailComponent,
    MakeOfferComponent,
    EmployeeModalComponent,
  ],
  imports: [
    CommonModule,
    CommonTableComponent,
    FormsModule,
    RightSidebarComponent,
    PipeModule,
    CancellationReschedulePolicyComponent,
    FaqsComponent
  ],
  exports: [MakeOfferComponent]
})
export class QuotationModule { }
