import { Component, ElementRef, HostListener } from '@angular/core';
import { RouteConfigLoadEnd, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  isOpen = false; 
  selectedOption: string = '';
  dropdownOptions = [
    {
      'label': 'My Profile',
      'value': 'profile'
    },
    {
      'label': 'Notifications',
      'value': 'notification'
    },
    {
      'label': 'Logout',
      'value': 'logout'
    }
  ];
  userName: string = '';
  isLoggedIn: boolean = false;
  constructor(private authService: AuthService, private elementRef: ElementRef, private storage: StorageService, private router: Router){
    this.storage.getItem('local', 'authData').subscribe((userData: any) => {
      if(userData){
        this.isLoggedIn = true;
        this.userName = userData.name;
      } else {
        this.isLoggedIn = false;
      }
    })
  }
  
  @HostListener('blur')
  handleBlur() {
    this.isOpen = false;
  }

  @HostListener('document:click', ['$event'])
handleClickOutside(event: MouseEvent) {
  const clickedInside = this.elementRef.nativeElement.contains(event.target);
  if (!clickedInside) {
    this.isOpen = false;
  }

}

  logout(){
    this.authService.logout().subscribe(resp => {
        this.router.navigate(['/login']);
    });
  }



  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  selectOption(value: string) {
    this.selectedOption = value;
    this.isOpen = false;  
    if(value === 'logout'){
      this.logout();
    } else if(value === 'profile'){
      this.router.navigate(['/profile']);
    }
  }
}
