import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(public http: HttpService) { }

  apiBaseUrl = environment.apiBaseUrl;
  
  getBanners(): Observable<any> {
    return this.http.get(`${this.apiBaseUrl}/get-contractor-banner`);
  }

  getPolicies(user_type:string, page:string): Observable<any>{
    return this.http.get(`${environment.apiBaseUrl}/get-page?user_type=${user_type}&page=${page}`);
  }

  getFaqs(user_type:string, type:string): Observable<any>{
    return this.http.get(`${environment.apiBaseUrl}/get-faqs?user_type=${user_type}&type=${type}`);
  }

  getDocuments(employeeId?: number):Observable<any> {
    if(employeeId) {
      let params = new HttpParams().set('id', employeeId);
      return this.http.get(`${environment.apiBaseUrl}/contractor/my-documents-list`, {params: params})
    }else {
      return this.http.get(`${environment.apiBaseUrl}/contractor/my-documents-list`);
    }
  } 

  getCities(): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/get-cities`);
  }

  getMyReviews(page:string, employeeId?: any): Observable<any> {
    if(employeeId) {
      return this.http.get(`${environment.apiBaseUrl}/contractor/my-review-list?page=${page}&id=${employeeId}`);

    }else {
    return this.http.get(`${environment.apiBaseUrl}/contractor/my-review-list?page=${page}`);
    }
  }
}
