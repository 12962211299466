
<section class="component-container">
    <h3 class="page-title">{{ currentUrl == 'quotations' ? 'Quotations' : 'Job History'}}</h3>
    <div class="detail_container container">
        <div class="back_to_list row">
            <div class="col-12 back-button" (click)="goBack()">
                <img src="../../../assets/icons/back_arrow.svg" />
                <a>Back to list</a>
            </div>

        </div>
        <div class="job_details row" *ngIf="requestDetails">
            <!-- Request Detail -->
            <div class="col-md-6 col-sm-12">
                <!-- Request detail -->
                <div class="service_details">
                    <h5 class="d-flex">{{ requestDetails.title ? requestDetails.title : (requestDetails.service_name+' Services') }}  <span class="home d-flex"><img class="mr-1" src="../../../assets/icons/home.svg" />{{ requestDetails.space_type }}</span></h5>
                    <h6 *ngIf="requestDetails.subservice_name" class="sub_service">{{ requestDetails.subservice_name }}</h6>
                    <p *ngIf="requestDetails.description" class="service_description">{{ requestDetails.description }}</p>
                    <ng-container *ngIf="requestDetails.additional_data.length > 0">
                    
                    <ul *ngIf="requestDetails.additional_data" class="additonal_data">
                        <li *ngFor="let item of requestDetails.additional_data; let i = index">
                            <b>{{ item.title }}</b><span>{{item.value < 10 ? '0' : ''}}{{item.value}}</span>
                        </li>
                    </ul>
                </ng-container>
                    <hr>
                </div>
                <!-- Additional details -->
                <div class="service_additonal_details">
                    <div class="address_details">
                        <div class="request_address mb-3 d-flex">
                            <img class="mr-2" src="../../../assets/icons/location.svg" />
                            <span>{{ requestDetails?.address?.full_address }}</span>
                        </div>
                        <div class="request_date  mb-3 d-flex">
                            <img class="mr-2" src="../../../assets/icons/date.svg" />
                            <span>{{ requestDetails.start_date | date }}</span>
                        </div>
                        <div class="request_time  mb-3 d-flex">
                            <img class="mr-2" src="../../../assets/icons/time.svg" />
                            <span>{{  requestDetails.start_date+" "+requestDetails.start_time  | date:"hh:mm a" }}</span>
                        </div>
                    </div>
                    <div class="images row ml-2" *ngIf="requestDetails.images || requestDetails.videos">
                        <div class="col-md-2 col-sm-6 shadow" *ngFor="let img of requestDetails.images;">
                            <img src="{{ img }}" class="img-rounded" alt="Cinque Terre">
                        </div>
                        <ng-container *ngFor="let vid of requestDetails.videos;">
                            <div class="col-md-2 col-sm-6 embed-responsive embed-responsive-1by2 shadow">
                            <video class="embed-responsive-item" autoplay control allowfullscreen preload="metadata">
                                <source [src]="vid" type="video/mp4">
                            </video>
                            </div>
                        </ng-container>
                    </div>
                    
                </div>
            </div>

            <!-- Offer list -->
            <div class="col-md-6 col-sm-12 border-left" *ngIf="requestDetails.accepted_quotation">
                <h5 class="mt-2">Applied Offers</h5>
                <hr />
                <div class="card" >
                    <div class="card-body">
                      <h5 class="card-title sub_service">Offer Detail</h5>
                      <div class="card-text d-flex">
                        <img class="mr-2" src="../../../assets/icons/money.svg" />
                        <h6 class="mr-2 mt-1"><b>${{ requestDetails.accepted_quotation.cost }}</b></h6>
                        <button class="btn btn-outline-secondary rounded btn-sm">{{ requestDetails.accepted_quotation.total_timeline+" "+requestDetails.accepted_quotation.timeline_type }}</button>
                      </div>
                      <p class="">{{ requestDetails.accepted_quotation.description }}</p>
                     <div class="d-flex"><b class="mr-2 mt-1">Status: </b>  <button type="button"  [ngClass]="{'btn': true, 'btn-sm': true,  'btn-theme-outline': requestDetails.accepted_quotation.status=='Received', 'btn-outline-success': (['Accepted','Completed'].includes(requestDetails.accepted_quotation.status)), 'btn-outline-danger': (['Declined','Cancelled'].includes(requestDetails.accepted_quotation.status))}">{{ requestDetails.accepted_quotation.status }}</button></div>
                    </div>
                </div>
                <div class="card mt-4" *ngIf="requestDetails.assigned_quotation">
                    <div class="card-body">
                      <div class="card-text d-flex">
                        <img width="70px" height="70px " class="rounded-circle theme-border" src="{{ requestDetails.assigned_quotation.image }}" />
                        <div class="ml-3">
                            <h6>{{ requestDetails.assigned_quotation.name }}</h6>
                            <p>{{ requestDetails.assigned_quotation.expertises }}</p>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="card mt-4" *ngIf="requestDetails.job_review">
                    <div class="card-body">
                      <div class="card-text">
                        <p class="text-end font-gray">{{ requestDetails.job_review.created_at | date:"MMM dd, yyyy 'at' hh:mm a" }}</p>
                        <p>{{ requestDetails.job_review.message }}</p>
                        <div class="d-flex">
                            <ng-container *ngFor="let item of [].constructor(+requestDetails.job_review.reviews); index as i">
                                <img class="mr-2" src="../../../assets/icons/star_fill.svg" />
                            </ng-container>
                            <ng-container *ngFor="let item of [].constructor(5- (+requestDetails.job_review.reviews)); index as i">
                                <img class="mr-2" src="../../../assets/icons/star_empty.svg" />
                            </ng-container>
                            
                        </div>
                      </div>
                    </div>
                </div>
                <div class="card mt-4" *ngIf="requestDetails.payment_details">
                    <div class="card-body">
                        <h5 class="card-title sub_service">Payment Details</h5>
                      <div class="card-text">
                           <div class="row">
                                <div class="col-6 mb-2"><strong>Total Amount:</strong> ${{ requestDetails.payment_details.task_amount }}</div>
                                <div class="col-6 mb-2"><strong>Admin Charge:</strong> ${{ requestDetails.payment_details.admin_charge }}</div>
                                <div *ngIf="requestDetails.accepted_quotation.penalty" class="col-6 mb-2"><strong>Penalty:</strong> ${{ requestDetails.accepted_quotation.penalty }}</div>
                                <div *ngIf="requestDetails.payment_details.purchase_price" class="col-6 mb-2"><strong>Purchase Price:</strong> ${{ requestDetails.payment_details.purchase_price }}</div>
                                <div class="col-6 mb-2"><strong>Payable Amount:</strong> ${{ requestDetails.payment_details.payable_amount }}</div>
                                <div class="col-6 mb-2"></div>
                           </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <ng-container *ngIf="requestDetails" >
                
                <app-cancellation-reschedule-policy></app-cancellation-reschedule-policy>
                <app-faqs></app-faqs>
            </ng-container>
            </div>

            
        </div>
        <div class="request-actions" *ngIf="requestDetails">
            <button *ngIf="requestDetails.accepted_quotation && (requestDetails.status =='Scheduled' || requestDetails.status =='Open')" type="button" class="btn btn-theme-outline mt-2 mb-2 d-flex rounded"><img class="mr-1 mt-1" src="../../../assets/icons/close.svg" /><span>Cancel Quotation</span></button>
            <button (click)="makeOffer()" *ngIf="!requestDetails.accepted_quotation && requestDetails.status =='Open'" type="button" class="btn btn-theme btn-lg  mt-5 mb-4">Make Offer</button>
            <button *ngIf="requestDetails.accepted_quotation && requestDetails.status =='Scheduled' && login_type=='COMPANY'"
                type="button" class="btn btn-theme btn-lg  mt-2 mb-2" (click)="openEmployee()">Assign to
                Employee</button>
            
        </div>
        <ng-template #else>
            <div>
                <p>Loading data...</p>
        </div>
        </ng-template>
        </div>
        
        <app-employee-modal *ngIf="employeeList" (close)="closeEmployeeModal()" [id]="requestDetails.accepted_quotation.id">
        
        </app-employee-modal>
<!-- <app-right-sidebar></app-right-sidebar> -->
<app-make-offer *ngIf="offer" (close)="closeSideOverlay()" [id]="requestDetails.id"></app-make-offer>
</section>