<div *ngIf="tableData?.length > 0;" class="navigation-button mb-3">
  <button class="btn btn-sm btn-theme-outline pagination-button" (click)="pageChange('left')" [disabled]="currentPage === 1">&#11160;</button>
  <p class="btn mb-0">{{currentPage}}</p>
  <button class="btn btn-sm btn-theme-outline pagination-button" (click)="pageChange('right')" [disabled]="currentPage === lastPage" >&#11162;</button>
</div>
<div class="custom-table-container">
<table class="curved-scroll-table quotation_list shadow" [cellPadding]="10">
    <thead>
      <tr>
        <ng-container *ngFor="let column of columnData">
          <th [width]="column.width"> {{column.title}} </th>
        </ng-container>
        </tr>
    </thead>
    
    <tbody>
      <ng-container *ngIf="tableData?.length > 0; else noDataTemplate">

        <ng-container *ngIf="tableFor === 'reviews'" >
          <tr *ngFor="let item of tableData; let i = index;">
            <td class="quote-image-container">
              <img style="height: 60px; width: 60px;" src="../../../assets/images/quote-image.svg" />
            </td>
            <td>
              <div>
                <b>{{item.service_name? (item.service_name+" Services - "+item.subservice_name) : item.title }}</b>
                <div class="d-flex mt-2">
                  <ng-container *ngFor="let item of [].constructor(+item.reviews); index as i">
                      <img class="mr-2" src="../../../assets/icons/star_fill.svg" />
                  </ng-container>
                  <ng-container *ngFor="let item of [].constructor(5- (+item.reviews)); index as i">
                      <img class="mr-2" src="../../../assets/icons/star_empty.svg" />
                  </ng-container>
                  
              </div>                
              <p class="mt-1">{{item.message}}</p>
              </div>
            </td>
            <td>
              <div>
                <div class="request_date  mb-2 d-flex">
                  <img class="mr-2" src="../../../assets/icons/date_gray.svg" />
                  <span>{{ item.start_date+' '+item.start_time | date:"dd MMM yyyy ' ' hh:mm a" }} </span>
                </div>
                <div class="request_date d-flex">
                  <img class="mr-2" src="../../../assets/icons/location_gray.svg" />
                  <span>{{item.full_address}}</span>
                </div>
              </div>
            </td>
            <td>
              <a href="javascript:void(0)" (click)="viewSelectRow(item)">
                <img src="../../../assets/icons/eye.svg" />
              </a>
            </td>
          </tr>
        </ng-container>

        <ng-container *ngIf="tableFor !== 'reviews'">
      <tr *ngFor="let item of tableData; let i = index;">
        <td>
          <div>
            <b>{{item.service_name? (item.service_name+" Services - "+item.space_type) : item.title }}</b>
            <p *ngIf="item.subservice_name" class="theme-color mt-1 mb-1">{{ item.subservice_name }}</p>
            <p class="mt-1">{{item.description}}</p>
          </div>
        </td>
        <td>
          <div>
            <div class="request_date  mb-2 d-flex">
              <img class="mr-2" src="../../../assets/icons/date_gray.svg" />
              <span>{{ item.start_date+' '+item.start_time | date:"dd MMM yyyy ' ' hh:mm a" }} </span>
            </div>
            <div class="request_date d-flex">
              <img class="mr-2" src="../../../assets/icons/location_gray.svg" />
              <span>{{item.delivery_address}}</span>
            </div>
          </div>
        </td>
        <td>
          <div>
            <button *ngIf="item.status=='Scheduled'" class="btn btn-sm btn-outline-success">{{item.status}}</button>
            <button *ngIf="item.status=='Open'" class="btn btn-sm btn-outline-warning">{{item.status}}</button>
            <button *ngIf="item.status=='Cancelled'" class="btn btn-sm btn-outline-danger">{{item.status}}</button>
            <button *ngIf="item.status=='Completed'" class="btn btn-sm btn-outline-danger">{{item.status}}</button>
          </div>
        </td>
        <ng-container *ngIf="tableFor !== 'cancelled-jobs'; else noActionView">
        <td>
          <a href="javascript:void(0)" (click)="viewSelectRow(item)">
            <img src="../../../assets/icons/eye.svg" />
          </a>
        </td>
      </ng-container>
        <ng-template #noActionView>
          <td>
            <div>
              <button class="btn btn-sm btn-outline-danger">{{item.cost}}</button>
            </div>
          </td>
          <td>
            <div>
              <button class="btn btn-sm btn-outline-danger">{{item.penalty}}</button>
            </div>
          </td>
        </ng-template>
        
        </tr>
      </ng-container>
      </ng-container>
      <ng-template #noDataTemplate>
        <tr>
          <th scope="row" class="no-record" colspan="100%">No Data Found</th>
        </tr>
      </ng-template>
    </tbody>
    
  </table>
</div>