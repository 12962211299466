import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { StorageService } from 'src/app/service/storage.service';
import { ToasterService } from 'src/app/service/toaster.service';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.css']
})
export class OtpVerificationComponent implements OnInit {
@Input() phone: string = '';
@Output() back = new EventEmitter();
@Output() loggedIn = new EventEmitter();

otp = '';
otp1: string = '';
otp2: string = '';
otp3: string = '';
otp4: string = '';


cooldown = 0; // Timer in seconds for resend cooldown
  cooldownTimer: any;
  resendEnabled: boolean = true;
  
  constructor(public authService: AuthService, public storage: StorageService, public router: Router, public toastr: ToasterService) {}

ngOnInit(): void {
  //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
  //Add 'implements OnInit' to the class.
  this.onResendClick();
}
goBack(){
  this.back.emit(true);
}

startCooldown(cooldownSeconds: number) {
  this.cooldown = cooldownSeconds;
  this.cooldownTimer = setInterval(() => {
    if (this.cooldown > 0) {
      this.cooldown--;
    } else {
      clearInterval(this.cooldownTimer);
      this.cooldownTimer = null;
    }
  }, 1000);
}

onResendClick() {
  if (this.resendEnabled && !this.cooldownTimer) {
    this.startCooldown(60); // Example cooldown of 60 seconds
  }
}

  onInput(event: any) {
    const target = event.target;
    const key = event.key.toLowerCase();
    if (key == "backspace" || key == "delete") {
      target.value = "";
      const prev = target.previousElementSibling;
      if (prev) {
          prev.focus();
      }
      return;
  }
    const char = event.target.value;
    if (isNaN(char)) {
      target.value = "";
      return;
  }
    if (char.length === 1 && /\d/.test(char)) {
      // this.otp = this.otp.slice(0, event.target.selectionStart) + char + this.otp.slice(event.target.selectionStart);
      this.focusNextInput(event.target);
    } else {
      event.target.value = '';
    }
  }

  resendOTP() {
    const [code, phone] = this.phone.split(' ');
    let data = {
      phone: phone,
      country_code: code
    }
    this.authService.getOTP(data).subscribe((resp: any) => {
      console.log(resp);
      if(resp){
        this.onResendClick();
        this.toastr.success('OTP Sent Successfully', 'Success');
      }
    })
  }

  focusNextInput(currentInput: any) {
    const nextInput = currentInput.nextElementSibling;
    if (nextInput) {
      nextInput.focus();
    }
  }

  login(){
    this.otp = `${this.otp1}${this.otp2}${this.otp3}${this.otp4}`;
    if(this.otp != '' && this.otp.length == 4){
      const [code, phone] = this.phone.split(' ');
      let data = {
        phone: phone,
        country_code: code,
        otp: this.otp
      }
      this.otp = '';
      this.authService.login(data).subscribe((resp: any) => {
        if(resp && resp.success == true){
          this.loggedIn.emit(resp);
        }
      })
    }
  }
}
