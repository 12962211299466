import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quotationType'
})
export class QuotationTypePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    let data;
    if(value.includes('_')) {
      data = value.split('_').join(' ');
    } else {
      data = value.split('-').join(' ');
    }
    let titleCase = data.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    return titleCase;
  }

}
