import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QuotationService } from '../quotation.service';
import { ToasterService } from 'src/app/service/toaster.service';

@Component({
  selector: 'app-make-offer',
  templateUrl: './make-offer.component.html',
  styleUrls: ['./make-offer.component.css']
})
export class MakeOfferComponent {
@Output() close = new EventEmitter();
@Input() id: any;

cost: any;
totalTimeline: any;
timeType: any = 'Days';
description: any;

constructor(public service: QuotationService, public toastr: ToasterService){}


  closeOverlay(){
    this.close.emit();
  }

  chooseTimeType(type: string) {
    this.timeType = type;
  }
  submit(form: any) {
    console.log(form.value, form.valid);
    if(form.valid) {
      let data = form.value;
      data['repair_request_id'] = this.id;
      this.service.postQuotations(data).subscribe((resp: any) => {
        console.log(resp);
        if(resp && resp?.success == true){
          this.toastr.success('Quotation posted succssfully.', 'Success');
        }
      })
    }
  }
}
