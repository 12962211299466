<div class="overlay">
  <div class="makeOffer shadow">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Assign job to employee</h5>
        <button type="button" class="close" data-dismiss="modal"
          aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body custom-scrollbar">
        <ng-container *ngFor="let employee of employees">
          <div class="card mt-2" (click)="selectEmployee(employee)">
            <div class="card-body">
              <div class="card-text d-flex">
                <img width="50px" height="50px " class="rounded-circle theme-border" [src]="employee.image" />
                <div class="ml-3 name-block">
                  <h6>{{employee.name}}</h6>
                  <p class="mb-0 ">{{employee.expertises_name | servicesSeperator}}</p>
                </div>
                <span class="ml-auto mt-3 " *ngIf="employee.id === selectedEmployeeId">
                  <img src="../../../assets/icons/selected.svg" />
                </span>
                <span class="ml-auto mt-3 " *ngIf="employee.id != selectedEmployeeId">
                  <img
                  src="../../../assets/icons/deselected.svg" />
                </span>
              </div>
            </div>
          </div>
        </ng-container>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-theme" (click)="assignEmployee()" [disabled]="!selectedEmployeeId">Assign</button>
        <button type="button" class="btn btn-outline-secondary"
          data-dismiss="modal" (click)="closeModal()">Cancel</button>
      </div>
    </div>
  </div>
</div>