import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, startWith } from 'rxjs';


type Storage = 'local' | 'session';
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private dataSubject = new Subject<any>();

  constructor() { }

  setItem(storage: Storage, key: string, value: any): void {
    if(storage === 'local') { 
    localStorage.setItem(key, JSON.stringify(value));
    } else {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
    this.dataSubject.next(value); // Update data subject
  }

  getItem(storage: Storage,key: string): Observable<any| null> {
    if(storage === 'local') { 
    const item = localStorage.getItem(key);
    return this.dataSubject.asObservable().pipe(
      // Emit initial value if not already emitted
      startWith(item ? JSON.parse(item) : null)
    );
  } else {
    const item = sessionStorage.getItem(key);
    return this.dataSubject.asObservable().pipe(
      // Emit initial value if not already emitted
      startWith(item ? JSON.parse(item) : null)
    );
  }
  }

  removeItem(storage: Storage, key: string): void {
    if(storage === 'local'){
      localStorage.removeItem(key);
    } else {
      sessionStorage.removeItem(key);
    }
    this.dataSubject.next(null); // Update data subject
  }

  clear(storage: Storage): void {
    storage === 'local' ? localStorage.clear() : sessionStorage.clear();
    this.dataSubject.next(null); // Update data subject
  }
}
