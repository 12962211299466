import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-common-table',
  templateUrl: './common-table.component.html',
  styleUrls: ['./common-table.component.css'],
  standalone: true,
  imports: [CommonModule]
})
export class CommonTableComponent {

  @Input() data!: any;
  @Input() columnData!: any;
  @Input() tableFor: any;
  @Output() viewRow = new EventEmitter();
  @Output() changePage = new EventEmitter();


  // data = [];
  tableData: any;
  currentPage: number = 1;
  lastPage: number = 1;
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    console.log(this.data);
    
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(this.data) {
      this.tableData = this.data.data;
      this.currentPage = this.data.current_page;
      this.lastPage = this.data.last_page;
    }
  }
  
  viewSelectRow(row: any) {
    console.log(row);
    this.viewRow.emit(row);
  }

  pageChange(type: string) {
    console.log(type);
    if(type === 'left') {
      this.changePage.emit(this.currentPage-1);
    } else if(type === 'right' && this.currentPage <= this.lastPage){
      this.changePage.emit(this.currentPage+1);
    }
    
  }
}
