import { Component, EventEmitter, Output } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { constants } from 'src/app/utils/constant';

@Component({
  selector: 'app-mobile-input',
  templateUrl: './mobile-input.component.html',
  styleUrls: ['./mobile-input.component.css']
})
export class MobileInputComponent {
  @Output() onOTPRequest = new EventEmitter();
  @Output() phoneNumber = new EventEmitter();
  mobilePattern = constants.mobileNumberPattern;
  countryCode:string = '+971'
  phone:string = ''
  // allCountries = constants.countries;
  countries = [
    { value: '+91', name: '+91' },
    { value: '+971', name: '+971' },
    { value: '+44', name: '+44' },
  ];

  constructor(public authService: AuthService){

  }

  getVerificationCode (form: any) {
    console.log(form);
    // this.onOTPRequest.emit(true);
    //       this.phoneNumber.emit(`${form.value.country_code} ${form.value.phone}`)
    if(form.valid){
      this.authService.getOTP(form.value).subscribe((resp: any) => {
        console.log(resp);
        if(resp){
          this.onOTPRequest.emit(true);
          this.phoneNumber.emit(`${form.value.country_code} ${form.value.phone}`)
        }
      })
    }
}

}
